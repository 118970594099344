import { ReactElement } from 'react';

import { useUpdateDependentForm } from '../../../../../contexts/UpdateDependentFormProviderGdf';
import { GoForwardButton } from '../../../GoForwardButton';
import { useWindowSizeStore } from '../../../../../stores/useWindowSizeStore';
import { useLoadingStore } from '../../../../../stores/useLoadingStore';
import { CartIcon } from '../../../../icons/CartIcon';
import Loading from '../../../../icons/Loading';

export function ConfirmForm(): ReactElement {
  const { handleSubmit, navigateToProposta } = useUpdateDependentForm();
  const loading = useLoadingStore(state => state.loading);

  const width = useWindowSizeStore(state => state.width);

  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(navigateToProposta)}
    >
      <>
        <h2 className="text-[2rem] font-semibold text-center text-primary-main">
          Suas informações foram recebidas com sucesso!
        </h2>
        <p className="font-normal mt-1 text-center mb-8">
          Caso o e-mail tenha sido preenchido corretamente, em breve, você
          receberá a confirmação do benefício Odontológico subsidiado pelo
          Governo do Distrito Federal.
        </p>
        <p className="font-normal mt-1 text-center mb-8">
          Clique no botão abaixo para ir para tela de confirmação.
        </p>
        <p className="font-normal font-semibold mt-1 text-center mb-8">
          Baixe o aplicativo da Odontogroup e encontre um dentista mais próximo
          de você!
        </p>
      </>
      <GoForwardButton
        disabled={loading}
        desktopText={loading ? '' : 'Finalizar compra'}
        mobileText={loading ? '' : 'Finalizar compra'}
      />
    </form>
  );
}
