import { ReactElement, useEffect, useState } from 'react';

import * as z from 'zod';

import { useUpdateDependentForm } from '../../../../../contexts/UpdateDependentFormProviderGdf';
import { Input } from '../../../../Input';
import { GoForwardButton } from '../../../GoForwardButton';
import { useRouteLoaderData } from 'react-router-dom';
import { validateCPF } from '../../../../../utils/ValidateCPF';
import { AssociadoSchema } from '../../../data/schemaDependent/AssociadoSchema';

function validateAssociado(obj: any): boolean {
  try {
    AssociadoSchema.parse(obj);
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return false;
    }
    return false;
  }
}

export function ServidorValidationForm(): ReactElement {
  const { register, handleSubmit, goToNextForm, errors, watch, setValue } =
    useUpdateDependentForm();

  const [error, setError] = useState<string | null>(null);

  const CPF = watch('Associado.cpf');

  const [showError, setShowError] = useState(false);

  const validaDados = async () => {
    if (!CPF) {
      handleSubmit(goToNextForm)();
      return false;
    }

    const Associado = await getCPFDetails(CPF);
    console.log('Associado: ', Associado);
    if (Associado) {
      const titular = Associado.dependentes.find(
        x => x.codigoGrauParentesco == 1,
      );
      const dependentes = Associado.dependentes.filter(
        x => x.codigoGrauParentesco != 1,
      );
      if (titular) {
        setValue('Associado.id', Associado.codigo);
        setValue('Associado.name', titular.nomeDependente);
        setValue('Associado.plano', titular.codigoPlano);
        setValue('Associado.plano_vl', titular.valorPlano);
        setValue(
          'Associado.departamento',
          Associado.nomeDepartamento || 'Sem Departamento',
        );
        setValue('Associado.departamentoId', Associado.codigoDepartamento || 0);
        setValue('Associado.matricula', Associado.matricula);
        if (dependentes) {
          dependentes.forEach((dependente, index) => {
            setValue(
              `Associado.dependentes.${index}.name`,
              dependente.nomeDependente,
            );
            setValue(`Associado.dependentes.${index}.gender`, dependente.sexo);
            setValue(
              `Associado.dependentes.${index}.parentage`,
              dependente.codigoGrauParentesco,
            );
            setValue(
              `Associado.dependentes.${index}.plano`,
              dependente.codigoPlano,
            );
            setValue(
              `Associado.dependentes.${index}.plano_vl`,
              dependente.valorPlano,
            );
          });
        }
      }
    }
    const Validation = watch('Associado');
    console.log('Associado gravado: ', Validation);
    console.log('erros: ', errors);
    console.log('verificar: ', validateAssociado(Validation));

    if (!validateAssociado(Validation)) {
      setError('Associado Não encontrado');
      setShowError(true);

      setTimeout(() => {
        setShowError(false);
      }, 5000);

      return false;
    } else {
      setError(null);
      handleSubmit(goToNextForm)();
    }
    console.log('erros: ', errors);
    return true;
  };

  const getCPFDetails = async (cpf: string) => {
    const formatedCpf = cpf.replace(/[.\-\s]/g, '');
    const token = process.env.REACT_APP_TOKEN_S4E_V2;
    const getAssociado = await fetch(
      `${process.env.REACT_APP_GET_ASSOCIADO_CPF}?token=${token}&cpfAssociado=${formatedCpf}`,
    ).then(response => response.json());
    const AssociadoExiste = getAssociado.dados.filter(
      x => x.codigoDaEmpresa == 27855,
    );
    console.log('retorno Api: ', AssociadoExiste);
    if (AssociadoExiste[0]) {
      return AssociadoExiste[0];
    }
  };

  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(goToNextForm)}
    >
      <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
        Dados
      </h2>

      <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-6">
        <Input
          label="CPF"
          placeholder="CPF"
          activateMask
          mask="999.999.999-99"
          errorMessage={errors.Associado?.cpf?.message}
          {...register('Associado.cpf')}
        />
      </div>

      {showError && (
        <div className="fixed top-24 left-1/2 transform -translate-x-1/5 bg-red-500 text-white py-2 px-4 rounded shadow-md">
          <p className="text-sm font-medium">
            {error?.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
        </div>
      )}

      <GoForwardButton onForwardClick={validaDados} />
    </form>
  );
}
