import * as z from 'zod';
import { dependentsSchema } from './dependentesSchema';

export const AssociadoSchema = z.object({
  id: z.number(),
  name: z.string(),
  cpf: z.string(),
  plano: z.number(),
  plano_vl: z.optional(z.string()),
  matricula: z.string(),
  departamentoId: z.number(),
  departamento: z.string(),
  dependentes: z.optional(z.array(dependentsSchema)),
});
