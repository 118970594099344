import * as z from 'zod';

import { newDependentsSchema } from './newdependentsSchema';
import { AssociadoSchema } from './AssociadoSchema';

export const schema = z.object({
  Associado: AssociadoSchema,
  dependents: z.optional(z.array(newDependentsSchema)),
});

export type Schema = z.infer<typeof schema>;
