import type { ReactElement } from 'react';

import DownloadBox from './DownloadBox';

const FinancialSection = (): ReactElement => (
  <section className="w-full p-5 bg-new-gray">
    <h3
      className="text-[40px] leading-[60px] text-center text-neutral-with max-[1200px]:text-2xl max-[1200px]:max-w-[266px]"
      style={{ color: '#272d65' }}
    >
      Demonstrativos Financeiros
    </h3>
    <div className="flex justify-center gap-5 max-[840px]:flex-col mb-10">
      <div className="flex flex-col gap-5">
        <DownloadBox
          title="Demonstrativo 2023"
          href="https://www.odontogroup.com.br/wp-content/uploads/2024/03/OdontoGroup-Demonstracoes-Contabeis-2023.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2021"
          href="https://www.odontogroup.com.br/wp-content/uploads/2022/05/demonstrativo_contabil_2021.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2019"
          href="https://www.odontogroup.com.br/documentos/Demonstrativo_2019.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2017"
          href="https://www7.odontogroup.com.br/documentos/Demonstrativo%20de%20Administra%C3%A7%C3%A3o%20-%20OdontoGroup%202017.pdf"
        />
      </div>
      <div className="flex flex-col gap-5">
        <DownloadBox
          title="Demonstrativo 2022"
          href="https://www.odontogroup.com.br/wp-content/uploads/2023/08/Demonstracoes_contabeis_2022.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2020"
          href="https://www.odontogroup.com.br/documentos/Demo2020.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2018"
          href="https://www7.odontogroup.com.br/documentos/Demonstrativo%20de%20Administra%C3%A7%C3%A3o%20-%20OdontoGroup%202018.pdf"
        />
      </div>
    </div>
  </section>
);

export default FinancialSection;
