import React, { useEffect } from 'react';

const UnafiscoRedirect: React.FC = () => {
  useEffect(() => {
    // Redireciona para o site externo
    window.location.href =
      'https://lp.odontogroup.com.br/unafisco/carteirinhadigital';
  }, []);

  return null; // Não renderiza nada
};

export default UnafiscoRedirect;
